@import 'theme/helpers';

material-angular-select {
  .material-angular-select {
    width: 100%;

    .mdl-textfield__input:focus {
      outline: none;
    }

    .is-item-hover {
      background-color: $default-item-hover-bg-color;
    }

    &.is-focused {
      .mdl-icon-toggle__label {
        color: $color-primary;
      }
    }

    .mdl-menu__item {
      color: $text-color-primary;
    }

    // custom scroll
    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      background: $scrollbar-thumb;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      background: $scrollbar-track;
    }

    .mdl-icon-toggle__label {
      color: $text-color-primary;
    }
  }
}
