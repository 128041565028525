@import 'theme/helpers';

.mdl-menu {
  // width: $dropdown-menu-width;

  .mdl-menu__item.mdl-list__item {
    display: flex;
  }
}

.mdl-menu__outline {
  @include shadow-4dp();
}
