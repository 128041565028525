@import 'theme/helpers';

.chart1__container,
.chart2__container,
.pie-chart__container,
.discrete-bar-chart__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  min-height: $pie-chart-height;

  svg {
    min-height: 200px;
    max-width: 180px;

    .nvd3.nv-pie {
      path {
        fill-opacity: 1;
        stroke-width: 0;
      }

      .nv-pie-title {
        @include typo-piechart-title;

        font-size: 22px !important;
      }
    }
  }

  .legend {
    max-width: 140px;
  }
}
