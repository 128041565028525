@import 'theme/helpers';

.pagination {
  font-family: Roboto, Helvetica, sans-serif;
  color: $color-smooth-gray;
  font-size: 14px;
  position: relative;
  line-height: 16px;
  user-select: none;

  .material-icons {
    cursor: pointer;
    position: relative;
    top: 0.5rem;
    margin: 0 0.5rem;
  }

  .goto {
    display: inline-block;
    margin: 0 1rem 0 2rem;

    form {
      width: 30px;
      display: inline-block;

      input {
        text-align: center;
        font-family: Roboto, Helvetica, sans-serif;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
