$color-dark-gray: #333;
$color-gray: #444;
$color-table-border: #606060;
$color-smooth-gray: #999;
$color-smoke: #666;
$color-red: #f44336;
$color-light-blue: #03a9f4;
$color-orange: #ffc107;
$color-amber: #ff9800;
$color-teal: #00bcd4;
$color-purple: #7726d3;
$color-green: #00d45a;
$color-white: #fff;
$color-black: #000;
$color-alto: #ddd;
$color-silver-chalice: #aaa;
$color-chambray: #3b5998;
$color-celurean: #00abf1;
$color-lochmara: #0083be;
$color-froly: #f26798;
$color-dodger-blue: #4176fb;
$color-dove-gray: #676767;
$color-primary: rgb(0, 188, 212);
$color-secondary: rgb(244, 67, 54);
$color-baby-blue: rgb(116, 199, 209);
$color-cerulean: rgb(80, 150, 215);
$color-mint: rgb(96, 196, 150);
$color-grey-500: unquote("rgb(#{$palette-grey-500})");

.text-color--smoke {
  color: $color-smoke;
}

.color--gray {
  background-color: $color-gray !important;
}

.color--dark-gray {
  background-color: $color-dark-gray;
}

.color--smooth-gray {
  background-color: $color-smooth-gray !important;
}

.text-color--smooth-gray {
  color: $color-smooth-gray !important;
}

.color--red {
  background-color: $color-red !important;
}

.color-text--red {
  color: $color-red !important;
}

.color--orange {
  background-color: $color-orange !important;
}

.color-text--orange {
  color: $color-orange !important;
}

.color--amber {
  background-color: $color-amber !important;
}

.color-text--amber {
  color: $color-amber !important;
}

.color--green {
  background-color: $color-green !important;
}

.color-text--green {
  color: $color-green !important;
}

.color--light-blue {
  background-color: $color-light-blue !important;
}

.color-text--light-blue {
  color: $color-light-blue !important;
}

.color--purple {
  background-color: $color-purple !important;
}

.color-text--purple {
  color: $color-purple !important;
}

.color--teal {
  background-color: $color-teal !important;
}

.color-text--teal {
  color: $color-teal !important;
}
