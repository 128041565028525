@import 'theme/helpers';

form {
  &.form--basic {
    .mdl-textfield__label {
      font-size: 1rem;
    }
  }
}

.profile-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-top: 5px;

  &.profile-image--round {
    border-radius: 50%;
  }
}
