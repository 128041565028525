/* You can add global styles to this file, and also import other style files */
@import "theme/scss/variables";
@import 'flag-icon-css/css/flag-icon.min';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

img.lfm_main-logo {
  width:60px;
}

.lfm_title-text {
  align-items: center;
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: $card-title-font-size;
  font-weight: $card-title-text-font-weight;
  overflow: hidden;
  margin: 0;
}


.lfm_user_name_top_bar {
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:150px;
  text-align: right;
}

.custom-order-details-modal {
  mat-dialog-container {
    padding: 24px;
    background-color: #2B2B2B;
    border: #666666 ridge;
  }
}

.custom-set-status-modal {
  mat-dialog-container {
    padding: 24px;
    background-color: #2B2B2B;
    border: #666666 ridge;
  }
}

.custom-pause-resume-modal {
  mat-dialog-container {
    padding: 24px;
    background-color: #2B2B2B;
    border: #666666 ridge;
  }
}

.custom-list-orders-modal {
  mat-dialog-container {
    padding: 24px;
    background-color: #2B2B2B;
    border: #666666 ridge;
  }
}

.user-mat-menu {
  max-width: 320px !important;
}

.custom-user-info {
  line-height: 1;
  display: flex;
  min-height: 90px;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 16px;
  cursor: default;
  color: #fff;
  overflow: hidden;
}

.custom-card-container {
  text-align: center;
  height: 700px;
  width: 98%;
  margin: 10px !important;
}

.custom-card-title {
  text-align: center;
  height: 60px !important;
}

.select-panel-override {
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: auto;
  overflow: hidden;
  width: auto;
  z-index: 1;
  position: relative;
  background: #444;
  border-radius: 2px;
  box-sizing: border-box;
}
