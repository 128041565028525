@import 'theme/helpers';

.mdl-card__title {
  background-color: $card-title-background-color;
}

.mdl-card__supporting-text {
  line-height: 22px;
  width: calc(100% - #{$card-horizontal-padding*2});
  overflow: visible;
}

.mdl-card__actions {
  padding: 8px 16px;
}

.mdl-card {
  height: 100%;
  overflow: visible;
}
