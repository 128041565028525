@import 'theme/helpers';

.label {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 1);
  border-radius: 2px;
  height: $label-height;
  padding: $label-padding;
  margin: 0;
  margin-left: auto;
  font-size: 12px;
  margin-right: 4px;

  @include typo-label();

  &--mini {
    padding: 3px 8px 2px;
    cursor: pointer;
    vertical-align: middle;
  }

  &--transparent {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
  }
}
